import ledgerSigningMiamiViceDark from 'wallet-cds-web/assets/ledger-signing-dark-miami-vice.svg';

import { D_240_X_240 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function LedgerSigningMiamiDark({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={ledgerSigningMiamiViceDark}
      alt={alt}
      data-testid="ledger-signing-miami-vice-dark"
      width={width}
      height={height}
    />
  );
}
