import ledgerPluginMiamiViceDark from 'wallet-cds-web/assets/ledger-plugin-dark-miami-vice.svg';

import { D_200_X_200 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function LedgerPluginMiamiDark({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={ledgerPluginMiamiViceDark}
      alt={alt}
      data-testid="ledger-plugin-miami-vice-dark"
      width={width}
      height={height}
    />
  );
}
