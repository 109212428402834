import ledgerSigningLightMiami from 'wallet-cds-web/assets/ledger-signing-light-miami.svg';

import { D_240_X_240 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function LedgerSigningMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={ledgerSigningLightMiami}
      alt={alt}
      data-testid="ledger-signing-light-miami"
      width={width}
      height={height}
    />
  );
}
