import { defineMessages, useIntl } from 'react-intl';
import primeDeFiRectangleMiamiVice from 'wallet-cds-web/assets/prime-defi-rectangle-miami-vice.svg';

import { D_200_X_100 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

const messages = defineMessages({
  defiNuxIllustrationsAlt: {
    defaultMessage: 'DeFi miami NUX illustration',
    description: 'DeFi Miami NUX illutration alt text',
  },
});

export function PrimeDeFiRectangleMiami({ scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const { formatMessage } = useIntl();
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={primeDeFiRectangleMiamiVice}
      alt={formatMessage(messages.defiNuxIllustrationsAlt)}
      data-testid="defi-nux-miami-vice"
      width={width}
      height={height}
    />
  );
}
