import cryptoAndMoreMiamiViceDark from 'wallet-cds-web/assets/crypto-and-more-miami-vice-dark.svg';

import { D_100_X_100 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function CryptoAndMoreMiamiDark({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={cryptoAndMoreMiamiViceDark}
      alt={alt}
      data-testid="crypto-and-more-miami-vice-dark"
      width={width}
      height={height}
    />
  );
}
