import documentMiami from 'wallet-cds-web/assets/document-miami-vice.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function DocumentMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;
  return (
    <img src={documentMiami} alt={alt} data-testid="document-miami" width={width} height={height} />
  );
}
