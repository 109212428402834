import { defineMessages, useIntl } from 'react-intl';
import recipientSuggestionsNuxMiamiVice from 'wallet-cds-web/assets/recipient-suggestions-nux-vice.svg';

import { D_100_X_100 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

const messages = defineMessages({
  recipientSuggestionsNuxAlt: {
    defaultMessage: 'No recipient suggestions found illustration',
    description: 'Alternative text for NUX illustration on Recipient Suggestions screen',
  },
});

export function RecipientSuggestionsNux({ scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const { formatMessage } = useIntl();
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={recipientSuggestionsNuxMiamiVice}
      alt={formatMessage(messages.recipientSuggestionsNuxAlt)}
      data-testid="recipient-suggestions-nux-illustration-miami-vice"
      width={width}
      height={height}
    />
  );
}
