import nftSpotRectangleNoFund from 'wallet-cds-web/assets/nft-spot-rectangle-no-fund-miami.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function NFTSpotRectangleMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={nftSpotRectangleNoFund}
      alt={alt}
      data-testid="nft-spot-rectangle-miami"
      width={width}
      height={height}
    />
  );
}
