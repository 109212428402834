import swapNuxMiami from 'wallet-cds-web/assets/swap-nux-miami.svg';

import { D_200_X_100 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function SwapNuxMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img src={swapNuxMiami} alt={alt} data-testid="swap-nux-miami" width={width} height={height} />
  );
}
