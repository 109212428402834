import referralsBonusMiamiVice from 'wallet-cds-web/assets/referralsBonus-vice.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function ReferralsBonusMiami({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return <img src={referralsBonusMiamiVice} alt={alt} width={width} height={height} />;
}
