import referralsBonusMiami from 'wallet-cds-web/assets/referralsBonus.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function ReferralsBonusMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return <img src={referralsBonusMiami} alt={alt} width={width} height={height} />;
}
