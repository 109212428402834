import { defineMessages, useIntl } from 'react-intl';
import primeDeFiRectangleMiami from 'wallet-cds-web/assets/prime-defi-rectangle-miami.svg';

import { D_200_X_100 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

const messages = defineMessages({
  defiNuxIllustrationsAlt: {
    defaultMessage: 'DeFi miami NUX illustration',
    description: 'DeFi Miami NUX illutration alt text',
  },
});

export function PrimeDeFiRectangleMiami({ scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const { formatMessage } = useIntl();
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={primeDeFiRectangleMiami}
      alt={formatMessage(messages.defiNuxIllustrationsAlt)}
      data-testid="defi-nux-miami"
      width={width}
      height={height}
    />
  );
}
