import ledgerAddAddressesMiamiVice from 'wallet-cds-web/assets/ledger-add-addresses-miami-vice.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function LedgerAddAddressesMiami({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={ledgerAddAddressesMiamiVice}
      alt={alt}
      data-testid="ledger-add-addresses-miami-vice"
      width={width}
      height={height}
    />
  );
}
