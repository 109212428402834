import wallet2MiamiVice from 'wallet-cds-web/assets/wallet2-miami-vice.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function Wallet2Miami({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;
  return (
    <img
      src={wallet2MiamiVice}
      alt={alt}
      data-testid="document-miami-vice"
      width={width}
      height={height}
    />
  );
}
