import lockMiami from 'wallet-cds-web/assets/lock-miami-vice.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function LockMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;
  return <img src={lockMiami} alt={alt} data-testid="lock-miami" width={width} height={height} />;
}
