import bridgeNuxMiami from 'wallet-cds-web/assets/bridging-nux-miami.svg';

import { D_200_X_100 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function BridgingPromotionTrayMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={bridgeNuxMiami}
      alt={alt}
      data-testid="bridge-nux-miami"
      width={width}
      height={height}
    />
  );
}
