import exploreDescentralizedAppsMiamiVice from 'wallet-cds-web/assets/explore-decentralized-apps-miami-vice.svg';

import { D_200_X_200 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function ExploreDecentralizedAppsMiami({
  alt,
  scaleMultiplier = 1,
}: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={exploreDescentralizedAppsMiamiVice}
      alt={alt}
      data-testid="explore-decentralized-apps-miami-vice"
      width={width}
      height={height}
    />
  );
}
