import walletReconnectAddress from 'wallet-cds-web/assets/wallet-reconnect-address-miami.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function WalletReconnectSuccessMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={walletReconnectAddress}
      alt={alt}
      data-testid="wallet-reconnect-address"
      width={width}
      height={height}
    />
  );
}
