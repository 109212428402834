import newAccountNuxMiamiViceDark from 'wallet-cds-web/assets/newAccountNux-dark-vice.svg';

import { D_200_X_100 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function NewMnemonicNuxMiamiDark({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={newAccountNuxMiamiViceDark}
      alt={alt}
      data-testid="new-account-nux-miami-vice-dark"
      width={width}
      height={height}
    />
  );
}
