import exploreDescentralizedAppsMiami from 'wallet-cds-web/assets/explore-decentralized-apps-miami.svg';

import { D_200_X_200 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function ExploreDecentralizedAppsMiami({
  alt,
  scaleMultiplier = 1,
}: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={exploreDescentralizedAppsMiami}
      alt={alt}
      data-testid="explore-decentralized-apps-miami"
      width={width}
      height={height}
    />
  );
}
