import { useSpectrum } from '@cbhq/cds-web';

import * as MiamiIllustrations from './MiamiIllustrations/MiamiIllustrations';
import * as MiamiIllustrationsDark from './MiamiIllustrations/MiamiIllustrationsDark';
import { MiamiIllustrationProps } from './MiamiIllustrations/types/MiamiIllustrationProps';
import * as MiamiViceIllustrations from './MiamiViceIllustrations/MiamiViceIllustrations';
import * as MiamiViceIllustrationsDark from './MiamiViceIllustrations/MiamiViceIllustrationsDark';

type Props = {
  isDarkMode: boolean;
  illustration: keyof typeof MiamiIllustrations; // illustration name is provided in light mode only
  isMiamiIllustration?: boolean;
  isMiamiViceIllustration?: boolean;
} & MiamiIllustrationProps;

/**
 * Get Miami and MiamiVice illustration Component Elements based on provided type name and isDarkMode and scaleMultiplier
 *
 * @param illustration - type name of MiamiIllustration
 * @param scaleMultiplier - scale multiplier for illustration
 * @param alt - alt text for the illustration
 * @returns Element pair of Miami and MiamiVice illustration within the mode and scaleMultiplier provided
 */
export function getMiamiIllustration({
  illustration,
  scaleMultiplier,
  alt,
  isDarkMode,
  isMiamiIllustration,
  isMiamiViceIllustration,
}: Props) {
  const miamiIllustrationDarkTypes = Object.keys(MiamiIllustrationsDark);
  const miamiViceIllustrationDarkTypes = Object.keys(MiamiViceIllustrationsDark);

  let MiamiComponent = MiamiIllustrations[illustration];
  let MiamiViceComponent = MiamiViceIllustrations[illustration];

  // parse darkmode components for miami vice illustrations
  if (isDarkMode) {
    const darkIllustration = illustration.concat('Dark');

    if (miamiIllustrationDarkTypes.includes(darkIllustration)) {
      MiamiComponent =
        MiamiIllustrationsDark[darkIllustration as keyof typeof MiamiIllustrationsDark];
    }

    if (miamiViceIllustrationDarkTypes.includes(darkIllustration)) {
      MiamiViceComponent =
        MiamiViceIllustrationsDark[darkIllustration as keyof typeof MiamiViceIllustrationsDark];
    }
  }

  return {
    MiamiIllustration:
      !!MiamiComponent && isMiamiIllustration ? (
        <MiamiComponent alt={alt} scaleMultiplier={scaleMultiplier} />
      ) : null,
    MiamiViceIllustration:
      !!MiamiViceComponent && isMiamiViceIllustration ? (
        <MiamiViceComponent alt={alt} scaleMultiplier={scaleMultiplier} />
      ) : null,
  };
}

export type MiamiIllustrationWrapperProps = {
  children: JSX.Element;
  illustration: keyof typeof MiamiIllustrations;
  isMiamiIllustration?: boolean;
  isMiamiViceIllustration?: boolean;
} & MiamiIllustrationProps;

export function MiamiIllustration({
  children,
  illustration,
  alt,
  scaleMultiplier,
  isMiamiIllustration = true,
  isMiamiViceIllustration,
}: MiamiIllustrationWrapperProps) {
  const isDarkMode = useSpectrum() === 'dark';

  const { MiamiIllustration: LegacyMiamiIllustration, MiamiViceIllustration } =
    getMiamiIllustration({
      illustration,
      scaleMultiplier,
      alt,
      isDarkMode,
      isMiamiIllustration,
      isMiamiViceIllustration,
    });

  if (isMiamiViceIllustration) {
    if (!MiamiViceIllustration) {
      throw new Error('Invalid vice illustration name');
    }
    return MiamiViceIllustration;
  }

  if (isMiamiIllustration && !LegacyMiamiIllustration) {
    throw new Error('Invalid illustration name');
  }

  return isMiamiIllustration && LegacyMiamiIllustration ? LegacyMiamiIllustration : children;
}
