import notificationsAltMiamiVice from 'wallet-cds-web/assets/notifications-alt-miami-vice.svg';

import { D_240_X_240 as dimension } from './consts/dimensions';
import { MiamiViceIllustrationProps } from './types/MiamiViceIllustrationProps';

export function NotificationsAlt({ alt, scaleMultiplier = 1 }: MiamiViceIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height;

  return (
    <img
      src={notificationsAltMiamiVice}
      alt={alt}
      data-testid="notifications-alt-miami-vice"
      width={width}
      height={height}
    />
  );
}
