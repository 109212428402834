import swapSelectAssetMiami from 'wallet-cds-web/assets/swap-select-asset-miami.svg';

import { D_240_X_120 as dimension } from './consts/dimensions';
import { MiamiIllustrationProps } from './types/MiamiIllustrationProps';

export function SwapSelectAssetMiami({ alt, scaleMultiplier = 1 }: MiamiIllustrationProps) {
  const width = scaleMultiplier * dimension.width;
  const height = scaleMultiplier * dimension.height * 2;

  return (
    <img
      src={swapSelectAssetMiami}
      alt={alt}
      data-testid="swap-select-asset-miami"
      width={width}
      height={height}
    />
  );
}
